export const FrFirebaseConfig = {
    /**
     * 從何取得 VAPID 金鑰？
     *
     * 1. https://console.firebase.google.com/u/0/project/futures-ai/settings/cloudmessaging/web:N2QzMjJlNmItNDc1NC00YWQ3LWE3NTMtNjY5MGRiMmQxZGZj?hl=zh-tw
     * 2. 網路設定
     * 3. 網頁推播金鑰
     */
    vapidKey: 'BBBJjeNpdomXx8E-IwO34ei2C9uzE_8XtUybrCKgrd4a8tfVtU1tri8A1s-PccgykC5yIsvZBU08aNExN1GaX_o',
};
