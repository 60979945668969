import { initializeApp } from 'firebase/app';
import { FacebookAuthProvider, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { collection, doc, getDoc, getFirestore, } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import { FrFirebaseConfig } from '~/modules/SDK/firebase/FrFirebaseConfig';
import { expectType } from '~/utils/tsd';
const app = initializeApp({
    appId: '1:463877906717:web:e0d20e134da849ab29c1f4',
    apiKey: 'AIzaSyCn5dseBaKD4ESxCFdRvqNasYVVsDmtNs4',
    authDomain: 'futures-ai.firebaseapp.com',
    databaseURL: 'https://futures-ai.firebaseio.com',
    projectId: 'futures-ai',
    storageBucket: 'futures-ai.appspot.com',
    messagingSenderId: '463877906717',
});
export const FirebaseApp = app;
/** 這是底層 firebase/firestore 官方實例 */
const db = getFirestore(app);
export const FrFirebase = {
    facebookProvider: new FacebookAuthProvider(),
    googleProvider: new GoogleAuthProvider(),
    /** 這是底層 firebase/auth 官方實例 */
    auth: getAuth(app),
    /**
     * 使用此方法能夠自動推斷出型別
     *
     * ## 擴充自動類型推斷
     *
     * - {@link FrFirebaseTypes.CollectionPath}
     * - {@link FrFirebaseTypes.CollectionDataMapp}
     *
     * @example
     *   //
     *   FrFirebase.getCollectionRef('san_daily_price_line')
     *   FrFirebase.getCollectionRef('symbol_watch_list')
     *
     * @example
     *   //
     *   await getDocs(
     *     query(
     *       FrFirebase.getCollectionRef('idea_posts'), //💡
     *       orderBy('publish_date', 'desc'),
     *       limit(cursor),
     *     ),
     *   )
     */
    getCollectionRef(collectionPath) {
        return collection(db, collectionPath);
    },
    /**
     * 使用此方法能夠自動推斷出型別
     *
     * ## 擴充自動類型推斷
     *
     * - {@link FrFirebaseTypes.CollectionPath}
     * - {@link FrFirebaseTypes.CollectionDataMapp}
     *
     * @example
     *   //
     *   onSnapshot(
     *     FrFirebase.getDocRef('symbol_watch_list', uid), //💡
     *     docSnap => setSymbolList(docSnap.data()),
     *   )
     *
     * @example
     *   //
     *   onSnapshot(
     *     FrFirebase.getDocRef('san_daily_price_line', selectedDate), //💡
     *     docSnap => {
     *       const data = docSnap.data()
     *       useFivePricesStore.setState({ prices: data?.price ?? [] })
     *       setPrices(data?.price ?? [])
     *     },
     *   )
     */
    getDocRef(collectionPath, docPath) {
        return doc(db, collectionPath, docPath || '__MISSING_DOC_PATH__');
    },
    getMessaging: () => {
        return getMessaging(app);
    },
    /**
     * 取得咱們唯一個 firebaseApp 的 messaging 的 "device_token" 或稱 "registration_tokens"
     *
     * 這個 token 我們需要在前端取得，然後並發送給我們的後端完成雲端通知裝置的註冊。
     *
     * 這個 token 即使你使用的是同一個 google 登入，每次取得時，取得結果，也有機會都不一樣。
     *
     * 這個 token 即使你使用的是同一個 google 登入，你使用不同的裝置，取得結果，也有機會都不一樣。
     */
    async getTokenOfFCM() {
        const fcmToken = await getToken(FrFirebase.getMessaging(), {
            vapidKey: FrFirebaseConfig.vapidKey,
        }).catch(err => {
            throw new Error(`無法取得 FCM token`, { cause: err });
        });
        if (fcmToken) {
            console.info('🟢 OK, 將令牌發送到您的服務器，並在必要時更新用戶界面。');
            return fcmToken;
        }
        throw new Error(`🔴 BAD, 沒有可用的註冊令牌。請求生成一個權限。 fcmToken = ${fcmToken}`);
    },
    async requestMessagingPermission() {
        console.info('🟡 正在請求被通知的權限...');
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.info('🟢 允許被通知');
            return permission;
        }
        throw new Error(`🟡 被通知權限狀況不明。 permission = ${permission}`);
    },
};
/* istanbul ignore next */
function TypingTesting() {
    async function Test1() {
        const ownDoc = await getDoc(FrFirebase.getDocRef('symbol_watch_list', 'uid'));
        expectType(ownDoc.data());
    }
}
