import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { expectType } from '~/utils/tsd';
const log = debugAPI.me.logger.extend('MeAPI');
export class MeAPI extends Urlu {
    notify = {
        postFcmTokens: new Urlu.Query((params) => {
            return this.request.axios.post(`/notify/fcmTokens`, params);
        }),
    };
    postActivationCode = new Urlu.Query((activationCode) => {
        log(`.postActivationCode()`);
        return this.request.axios
            .post(`/activation/apply`, {
            activationCode,
        })
            .then(res => res.data)
            .catch((res) => {
            const errorMapp = {
                UNKNOWN: '未知錯誤',
                UNAVAILABLE_ACTIVATION_CODE: '不屬於啟動碼可啟用期間',
                NOT_QUALIFIED: '限定新用戶才可使用的啟動碼',
                ALREADY_USED_ACTIVATION_CODE: '已經啟用過相同啟用碼',
                ACTIVATION_CODE_NOT_FOUND: '不存在的啟用碼',
            }[res.response?.data.message || 'UNKNOWN'];
            throw new Error(errorMapp);
        });
    });
    /** 使新用戶在訂閱.status === null情況下進行3天試用 */
    postFreeTrial = new Urlu.Query(() => {
        log(`.postFreeTrial()`);
        return this.request.withAgent.withProduct
            .axios({
            method: 'post',
            url: '/trial',
        })
            .then(() => {
            return this.getMe.fetch();
        })
            .catch(() => {
            return null;
        });
    });
    /**
     * 若放上這個 searchParams.agentProduct 會造成 GET /me 漏回傳策略的權限
     *
     * `searchParams: { agentProduct: get().agentProduct }`
     *
     * TODO: 升 next12 之前就已醬放著了，沒人反應有問題，目前有影響到什麼嗎？修嗎？
     */
    getMe = new Urlu.Query(() => {
        log(`.getMe()`);
        return this.request.withAgent.axios
            .get(`/me`)
            .then(res => {
            useMeStore.setState({ meUserState: res.data });
            return res.data;
        })
            .catch(() => {
            useMeStore.setState({ meUserState: null });
            return null;
        });
    });
    getInfo = new Urlu.Query(() => {
        log(`.getInfo()`);
        return this.request.axios
            .get('/me/info')
            .then(res => {
            useMeStore.setState({ meInfoState: res.data });
            return res.data;
        })
            .catch(() => {
            useMeStore.setState({ meInfoState: null });
            return null;
        });
    });
    postInfo = new Urlu.Query((params) => {
        log(`.postInfo()`);
        return this.request.axios
            .post('/me/info', params)
            .then(res => {
            useMeStore.setState({ meInfoState: res.data });
            return res.data;
        })
            .catch(() => {
            useMeStore.setState({ meInfoState: null });
            return null;
        });
    });
    changePassword = new Urlu.Query((params) => {
        log(`.changePassword()`);
        return this.request.axios.post('/me/password', params);
    });
    changeUserPassword = new Urlu.Query((params) => {
        return this.request.withAgent.axios
            .put(`/users/${params.uid}/password`, {
            password: params.password,
        })
            .then(res => res.data);
    });
    /**
     * 當使用者到了 /signup 頁面時 || 或是使用者第一次登入 firebase 之後
     *
     * - 丟給後端一個紀錄
     * - 取代 POST /me
     *
     * 會有一個立即過期的商品，並且 subscription.status === null
     */
    signup = new Urlu.Query(() => {
        log(`.signup()`);
        const firebaseState = useMeStore.getState().meFirebaseState;
        return this.request.withAgent
            .axios({
            method: 'post',
            url: '/me/signup',
            data: {
                name: firebaseState?.name,
                photo: firebaseState?.avatarUrl,
                email: firebaseState?.email,
                providerUid: firebaseState?.providerUid,
                provider: firebaseState?.provider,
                jwt: undefined, // ！不要傳送 jwt
            },
        })
            .then(res => res.data);
    });
}
/* istanbul ignore next */
function TypingTesting() {
    const me = new MeAPI(``);
    me.getInfo.fetch().then(foo => {
        // @ts-expect-error 不能是 any（所以要修到沒紅線）
        expectType(foo);
        expectType(foo);
    });
    expectType(me.getInfo.useAsync().data);
}
